.all_plan {
  padding: 60px;

  img {
    width: 400px;
  }
  .home_button {
    visibility: hidden;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
    z-index: 3;
  }
  .text_center {
    position: relative;
    z-index: 1;
    margin: 0 20px;

    &:hover {
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: black;
        z-index: 2;
        opacity: 0.2;
      }

      .home_button {
        visibility: visible;
      }
    }
  }
}

@media (max-width: 600px) {
  .all_plan {
    padding: 10px;
    .container {
      flex-wrap: wrap;
    }
    img {
      width: 200px;
    }
    .plan {
      flex-wrap: wrap;
    }
  }
}
