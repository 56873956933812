.Price_warpper {
  padding: 60px;
  background: #f4f8ff;
  text-align: center;
  .price_table {
    margin-top: 20px;
    background: #fff;
    .table-responsive {
      table {
        width: 100%;
        thead {
          background-color: #282828;
          tr {
            th {
              color: #fff;
              text-align: left;
              padding-left: 30px !important;
              padding: 12px;
              font-size: 16px;
              font-weight: 700;
              border-right: 1px solid #c4c4c4;
              &:first-child {
                border-left: 1px solid #c4c4c4;
              }
            }
          }
        }
        tbody {
          tr {
            &:nth-child(2n-1) {
              background-color: rgba(0, 0, 0, 0.05);
            }
            td {
              text-align: left;
              padding-left: 30px !important;
              padding: 12px;
              font-size: 16px;
              font-weight: 700;
              border-right: 1px solid #c4c4c4;
              border-bottom: 1px solid #c4c4c4;
              &:first-child {
                border-left: 1px solid #c4c4c4;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .Price_warpper {
    padding: 30px;

    .price_table {
      margin-top: 10px;
      overflow: auto;
      .table-responsive {
        table {
          thead {
            tr {
              th {
                padding-left: 10px !important;
                padding: 6px;
                font-size: 12px;
              }
            }
          }
          tbody {
            tr {
              td {
                text-align: left;
                padding-left: 10px !important;
                padding: 6px;
                font-size: 12px;
                white-space: nowrap;
              }
            }
          }
        }
      }
    }
  }
}
