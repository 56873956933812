.whychoseWarp {
  text-align: center;
  padding: 60px;

  .box_wrapper {
    display: flex;
    flex-direction: column;
    margin: 60px;
    text-align: left;
    .WhychoseBIx {
      background: #fbfbfb;
      padding: 30px 30px;
      margin-bottom: 20px;
      margin-right: 15px;
      border-radius: 5px;
      border: 2px solid #d5d5d5;
      height: 70%;
      text-align: center;
      .common_content {
        text-align: justify;
      }
    }
  }
}

@media (max-width: 600px) {
  .whychoseWarp {
    text-align: center;
    padding: 30px;

    .box_wrapper {
      flex-direction: column;
      .WhychoseBIx {
        padding: 10px 10px;

        height: 80%;
        .image {
          width: 40px;
        }
        .common_secondary_heading {
          font-size: 16px;
        }
        .common_content {
          text-align: justify;
          font-size: 12px;
        }
      }
    }
  }
}
