.Amenities_warpper {
  padding: 60px;
  text-align: center;
  background-color: #f4f8ff;

  .cards_container {
    margin: 0 1.5rem;
    // display: flex;
    // flex-wrap: wrap;

    .amenity_box {
      height: 67%;
      background: #fff;
      padding: 40px 20px;
      border-radius: 15px;
      transition: all 0.5s ease;
      margin-bottom: 30px;
      text-align: center;
      &:nth-child(2n-1) {
        margin-right: 30px;
      }
      &:hover {
        background: #ffcf3c;
      }

      .IconBox {
        margin-bottom: 30px;
        max-width: 100px;
        border: 1px solid #afafaf;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: #fff;
        margin: auto;
        img {
          max-width: 50px;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .Amenities_warpper {
    padding: 30px;
    .cards_container {
      .amenity_box {
        height: 67%;
        padding: 20px 10px;
        &:nth-child(2n-1) {
          margin-right: 10px;
        }
      }
    }
  }
}
