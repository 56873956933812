.navbar-wrapper {
  padding: 15px 75px 15px 75px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // background-color: blue;
  box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.2);
  position: sticky;
  top: 0;
  background-color: #ffff;
  z-index: 9999;

  .nav-left {
    .nav-logo {
      width: 160px;
    }
  }

  .nav-right {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 40px;
    span {
      // margin: 20px;
      // padding: 20px 0;
      cursor: pointer;

      &:hover {
        color: #c08831;
        // border-bottom: 1px solid blue;
        position: relative;
        transition: all 0.2s;
        border-bottom: 3px solid #c08831;
        // &::after {
        //     content: "";
        //     position: absolute;
        //     bottom: -10px;
        //     left: 0;
        //     width: 100%;
        // }
      }
    }
    .whatsapp-logo-wrapper {
      margin: unset;
      padding: unset;
      .whatsapp-logo {
        max-width: 100px;
      }

    }
  }
}

.drawer-open.mobile {
  display: none;
}

.homewrapper_main {
  position: relative;
  .home_wrapper {
    z-index: 1;
    position: relative;
    //   top: 20px;
    height: 95vh;
    .home_img {
      width: 100%;
      object-fit: cover;
      height: 100%;
    }
    &::after {
      content: "";
      position: absolute;
      width: 100%; /* Full width (cover the whole page) */
      height: 100%; /* Full height (cover the whole page) */
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      //   background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
      background-image: linear-gradient(
        to bottom,
        rgba(146, 146, 146, 0),
        rgba(0, 0, 0, 0.73)
      );
      z-index: 2;
    }
  }

  .home_content {
    position: absolute;
    bottom: 10%;
    left: 10%;
    width: 80%;
    z-index: 3;
    color: #ffff;

    .heading_wrapper {
      .primary_heading {
        font-size: 52px;
        margin: 10px 0;
      }

      .secondary_heading {
        font-size: 22px;
      }
    }

    .home_secondary_content {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      // max-width: 40%;
      .info_wrapper {
        display: flex;
        align-items: center;
        // justify-content: space-between;
        flex-wrap: wrap;
        max-width: 70%;

        .info {
          // padding: 20px 0;
          width: 33.3%;

          .info_heading {
            color: #c08831;
            font-size: 18px;
            font-weight: bold;
          }
          .info_value {
            font-size: 22px;
          }
        }
      }
    }
  }
}

.input_boxes_wrapper {
  .input_container {
    // width: 100%;
    padding: 15px 20px;
    input {
      padding: 15px 10px;
      border-radius: 5px;
      outline: none;
      border: 1px solid gray;
      width: 96%;
    }
  }
}

.half_width {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.d_flex {
  display: flex;
}

.PhoneInput {
  width: 100%;
  margin-right: 20px;
}

.MuiPaper-root.MuiPaper-elevation {
  max-width: 400px;
  .MuiTypography-root.MuiTypography-h6.MuiDialogTitle-root {
    white-space: normal;
    margin-right: 30px;
  }
}

.modal_btn {
  margin-right: 20px !important;
}

.dropdown {
  width: 100%;
  margin: 0 !important;
  .dropdown_inside {
    div {
      padding: 13px 10px !important;
    }
  }
}

.textarea_container {
  width: 96%;
  padding: 0 20px 0 0;
  box-sizing: border-box;
  textarea {
    width: 100%;
    border-radius: 5px;
    border: 1px solid grey;
    padding: 15px 10px;
  }
}

.font_bold {
  font-weight: bold !important;
}

.white_space_no_wrap {
  white-space: nowrap;
}

input::placeholder,
textarea::placeholder {
  font-weight: bold !important;
}

.home_button {
  padding: 10px 20px !important;
  background-color: #c08831 !important;
  border-radius: 50px !important;
  border: none !important;
  color: white !important;
  font-size: 18px !important;
  transition: all 0.5s;
  cursor: pointer;

  &:hover {
    background-color: #ffff !important;
    color: #c08831 !important;
  }
}

.small_btn {
  font-size: 14px !important;
}

.enquire_button {
  position: absolute;
  top: 50%;
  left: -87px;
  z-index: 9;
  transform: rotate(-90deg) translateX(15%);
  background-color: #c08831;
  color: #fff;
  padding: 10px 15px;
  border: none;
  cursor: pointer;
  border-radius: 10px 10px 0 0;
  font-size: 18px;
}

.show_form {
  position: fixed;
  top: 20%;
  right: 0;
  transition: right 0.5s linear;
  z-index: 9;
}

.hide_form {
  // right: -50px;
  position: fixed;
  z-index: 9;
  top: 20%;
  right: -340px;
  transition: right 0.5s linear;
}

.form_wrapper {
  max-width: 300px;
  background-color: white;
  border-radius: 10px 0 0 10px;
  padding: 20px;

  // height: 100%;

  // position: relative;
}

.toggle_form {
  text-align: center;
  padding: 15px;
  border: 1px dashed gray;

  .heading_wrapper {
    h3 {
      font-size: 22px;
      margin-bottom: 5px;
      color: #c08831;
      font-weight: 700;
      margin-top: 0;
    }
    p {
      font-weight: 600;
      font-size: 16px;
      line-height: 27px;
      color: #555555;
    }
  }
}

.toggle_form_body_wrapper {
  input,
  textarea {
    width: 93%;
    padding: 15px 0 15px 15px;
    margin-bottom: 15px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 4px;
  }
  .PhoneInputCountry {
    margin-bottom: 15px;
  }
  .dropdown {
    margin-bottom: 15px !important;
    // border: 1px solid #e6e6e6;
  }
}

.with_overlay {
  // &::after {
  // content: "";
  z-index: 7;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.6;
  // pointer-events: none;
  // }
}

span.divied {
  display: inline-block;
  background-color: #c08831;
  width: 50px;
  height: 4px;
  position: relative;
  top: -8px;
}

.common_heading {
  font-size: 34px;
  margin: 0;
  margin-bottom: 15px;
}

.common_secondary_heading {
  font-weight: 700;
  color: #000;
  margin-bottom: 17px;
  font-size: 22px;
}

.common_content {
  font-weight: 600;
  font-size: 16px;
  line-height: 27px;
  color: #555555;
}

.text_left {
  text-align: left;
}

.text_center {
  text-align: center;
}

@media (max-width: 600px) {
  .navbar-wrapper {
    padding: 0 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .nav-left {
      .nav-logo {
        width: 80px;
      }
    }

    .nav-right {
      span {
        margin: 5px;
        padding: 5px 0;
        font-size: 14px;
      }
    }
    .nav {
      display: hidden;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      opacity: 1;
      gap: 10px;
      height: 0;
      opacity: 0;
      // transition: 200ms ease-in;
      font-size: 10px;
      backdrop-filter: blur(10px);
    }
    .nav.active {
      display: flex;
      position: absolute;
      flex-wrap: wrap;
      bottom: -103px;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 1;
      gap: 10px;
      background-color: #ffffff;
      font-size: 10px;
      padding: 10px 0 40px 0;
    }
  }
  .drawer-open.mobile {
    display: flex;
  }
  .home_button {
    padding: 5px 10px !important;
    font-size: 12px !important;
    white-space: nowrap;
  }
  .homewrapper_main {
    .home_content {
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);

      .heading_wrapper {
        .primary_heading {
          font-size: 32px;
          margin: 5px 0;
        }

        .secondary_heading {
          font-size: 16px;
        }
      }

      .home_secondary_content {
        flex-direction: column;
        .info_wrapper {
          max-width: 100%;

          .info {
            width: 50%;
            .info_heading {
              font-size: 16px;
            }
            .info_value {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
  .enquire_button {
    position: absolute;
    top: 50%;
    left: -80px;
    z-index: 9;
    transform: rotate(-90deg) translateX(15%);
    background-color: #c08831;
    color: #fff;
    padding: 10px 15px;
    border: none;
    cursor: pointer;
    border-radius: 10px 10px 0 0;
    font-size: 16px;
  }
  .show_form {
    position: fixed;
    top: 15%;
    right: 0;
    transition: right 0.5s linear;
    z-index: 9;
  }

  .hide_form {
    // right: -50px;
    position: fixed;
    z-index: 9;
    top: 15%;
    right: -340px;
    transition: right 0.5s linear;
  }

  .form_wrapper {
    padding: 20px;
  }

  .toggle_form {
    text-align: center;
    padding: 10px;
    border: 1px dashed gray;

    .heading_wrapper {
      h3 {
        font-size: 18px;
        margin-bottom: 5px;
        color: #c08831;
        font-weight: 700;
        margin-top: 0;
      }
      p {
        font-weight: 600;
        font-size: 16px;
        line-height: 27px;
        color: #555555;
      }
    }
  }
  .common_heading {
    font-size: 26px;
  }
  .common_content {
    font-size: 12px;
  }
}

.drawer-open {
  width: 46px;
  height: 55px;
  cursor: pointer;
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
}
.drawer-open span,
.drawer-open span:before,
.drawer-open span:after {
  content: "";
  height: 2.5px;
  width: 25px;
  background-color: currentColor;
  // transition: 400ms 350ms;
  position: absolute;
}
.drawer-open span:before {
  bottom: 8px;
}
.drawer-open span:after {
  top: 8px;
}
.drawer-open.active span {
  background: rgba(255, 255, 255, 0);
  // transition: 0ms;
}
.drawer-open.active span::before {
  bottom: 0;
  // transition: all 400ms 100ms cubic-bezier(0.1, 0, 0.9, 0);
  transform: rotate(45deg);
}
.drawer-open.active span::after {
  top: 0;
  // transition: all 400ms 100ms cubic-bezier(0.1, 0, 0.9, 0);
  transform: rotate(-45deg);
}
