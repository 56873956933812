.privacy-container {
    margin: 10vh auto;
    max-width: 60vw;
    padding: 0 60px;
    border: 1px solid black;
    border-radius: 4px;
    .main_heading {
      text-align: center;
      margin: unset;
      font-size: 20px;
      font-weight: 500;
    }
    b {
      font-size: 15px;
      display: block;
    }
    p {
      font-size: 13px;
      line-height: 24px;
      padding-top: 10px;
    }
  }
  
  