
.footer_wrapper {
    background: #222120;
    padding: 20px 0 25px 0;
    color: #fff;
  
    .copyright {
      border-top: 1px solid #787676;
      margin: 70px 0 10px;
      .container {
          max-width: 70vw;
          margin: auto;
          text-align: center;
          font-size: 12px;
          line-height: 1.5;
          a {
              color: #007bff;
              text-decoration: none;
              display: block;
              font-size: 12px;
              margin-top: 10px;
          }
      }
    }
  }
  
  