.welcome_page {
  //   height: 100vh;
  background-color: #f4f8ff;
  padding: 60px;
  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .welcome_left {
      padding-right: 40px;
      width: 40%;
      // .primary_heading {
      //   font-size: 34px;
      //   margin: 0;
      // }
      p {
        font-weight: 600;
        font-size: 16px;
        line-height: 27px;
        color: #555555;
        text-align: justify;
      }
    }
    .welcome_right {
      width: 50%;
      .carousel-container {
        width: 100%;
        max-width: 1000px;
        height: 70vh;
      }
    }
  }
}

@media (max-width: 600px) {
  .welcome_page {
    padding: 30px;
    .container {
      flex-direction: column;
      gap: 20px;
      .welcome_left {
        width: 90%;
        padding-left: 20px;
        p {
          font-size: 12px;
        }
      }
      .welcome_right {
        width: 90%;
        .carousel-container {
          width: 100%;
          max-width: 500px;
          max-height: 70vh;
        }
      }
    }
  }
}
