.faq_wrapper {
  text-align: center;
  background-color: #f4f8ff;
  padding: 50px;
  .faq_body {
    padding: 40px 0 0 0;
    .faq_list {
      margin: auto;
      text-align: left;
      margin-bottom: 20px;
      border: 1px solid #b9b9b9;
      border-radius: 5px;
      background: #fafafa;
      max-width: 80vw;

      .question {
        margin: 0;
        cursor: pointer;
        padding: 11px 30px;
        color: #2c2c2c;
        font-size: 20px;
        font-weight: 700;
        position: relative;
        .right-arrow {
          width: 20px;
          height: 20px;
          position: absolute;
          right: 20px;
          top: 50%;
        }

        &.show_line {
          border-bottom: 1px solid #b9b9b9;
          .right-arrow {
            transform: translateY(-50%) rotate(90deg);
            transition: transform 0.5s ease;
          }
        }
        &.hide_line {
          border-bottom: 0 solid transparent;
          transition: all 0.6s ease;
          .right-arrow {
            transform: translateY(-50%);
            transition: transform 0.5s ease;
          }
        }
      }
      .answer {
        background-color: white;
        border-radius: 4px;
        overflow: auto;
        margin: 0;
        color: #777;
        font-weight: 600;

        &.show {
          overflow: hidden;
          max-height: 150px;
          transition: all 0.5s ease;
          overflow: hidden;
          box-sizing: border-box;
          padding: 15px 30px 20px;
        }
        &.hide {
          max-height: 0;
          transition: all 0.5s ease;
          margin: 0;
          overflow: hidden;
          padding: 0 30px;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .faq_wrapper {
    padding: 30px;
    .faq_body {
      padding: 20px 0 0 0;
      .faq_list {
        margin-bottom: 15px;
        .question {
          padding: 11px 35px 11px 20px;
          font-size: 14px;
          .right-arrow {
            width: 15px;
            height: 15px;
          }
        }
        .answer {
          font-size: 12px;
          &.show {
            overflow: auto;
            transition: all 0.5s ease;
            padding: 10px 15px;
          }
          &.hide {
            padding: 0 15px;
          }
        }
      }
    }
  }
}

